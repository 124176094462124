@media only screen and (max-width : 640px) {
  body {
    padding-top: 67.7833px;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
  }

  .b-galleryPage .b-spacer {
    margin-top: 0 !important;
  }

  .flex {
    flex-direction: column;
  }

  .b-header {
    width: 100%;
    padding: 0;
    padding-bottom: 15px;
  }
  .b-header--menu {
    width: 90%;
    flex-direction: row !important;
  }

  .parallax {
    background-attachment: initial;
  }
  #hero-home-main {
    background-attachment: fixed !important;
  }

  .b-socialMedia {
    margin: auto;
    padding: 10px;
  }

  .b-galleryPage--header {
    font-size: 1rem;
  }

  .b-threeImages {
    width: 100vw;
    height: auto !important;
  }

  .b-threeImages--smallImage.up {
    margin-bottom: 0 !important;
  }

  .b-threeImages--image {
    min-height: 50vh;
    width: 95vw;
    margin: auto;
    margin-top: 2%;
  }

  .b-threeImages--smallerOnes {
    width: 95vw;
    margin: auto;
  }

  .b-threeImages--smallerOnes.left {
    padding-left: 0 !important;
  }
  .b-threeImages--smallerOnes.right {
    padding-right: 0 !important;
  }

  .b-contactForm {
    width: 90%;
  }

  .b-textWithHeader {
    width: 90%;
  }
  .b-textWithHeader--header {
    width: 90%;
  }
  .b-textWithHeader--text {
    width: 90%;
  }

  .b-hero {
    /* height: 90vh; */
    margin-top: 4%;
  }
  .b-hero.flex {
    flex-direction: row;
  }
  .b-hero--text h1 {
    font-size: 3rem;
  }

  .b-dropmenu ul {
    top: 25px;
  }
}