@font-face {
  font-family: Freight Big Book;
  src: url('./assets/fonts/Freight.woff') format("opentype");
}

@font-face {
  font-family: MOD20;
  src: url('./assets/fonts/MOD20.TTF') format("opentype");
}

::selection {
  background-color: black;
  color: white;
}

.b-socialMedia--image {
  width: 20px;
  height: 20px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 62.2px;
  font-family: Freight Big Book, serif;
  font-weight: 300;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parallax {
  /* Full height */

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: black;
  transition: all .3s;
}
a:hover {
  color: #a55970;
}

.text-center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  letter-spacing: 0.05em;
}

.flex {
  display: flex;
}

.b-link {
  cursor: pointer;
  transition: color .3s;
}
.b-link:hover {
  color: #a55970;
}

.b-button {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  font-size: 0.90rem;
  transition: all .7s;
  cursor: pointer;
}
.b-hashbutton {
  color: black !important;
}
.b-buttonContainer--center {
  text-align: center;
}
.b-buttonContainer--left {
  text-align: left;
}
.b-buttonContainer--right {
  text-align: right;
}
.b-button--outlined {
  border: solid 1px rgba(128, 128, 128, 1);
}
.b-button--outlined:hover {
  border-color: transparent;
}

.b-header {
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px;
  position: fixed;
  z-index: 999;
  top: 0;
  width: calc(100% - 40px - 5%);
  background-color: white;
}
.b-header--container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.b-header--activeLink {
  color: #a55970 !important;
  /* font-weight: bold; */
  /* text-decoration: underline; */
}
.b-header--menu {
  width: 50%;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}
.b-header--menu span {
  display: inline-block;
}
.b-header--language {
  cursor: pointer;
  transition: all .3s;
}
.b-header--language:hover {
  color: #a55970;
}

.b-socialMedia {

}
.b-socialMedia--media {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 11px;
}

.b-footer {
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  width: 75%;
  margin: auto;
  margin-bottom: 2.5%;
  margin-top: 2.5%;
}

.b-hero {
  height: 89vh;
  width: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  justify-items: center;
  align-items: center;
  background-color: #d4d4d4;
}
#hero-home-main {
  background-color: transparent !important;
}

.b-hero--text {
  width: 100%;
  position: relative;
  top: 15px;
}
.b-hero--text h1 {
  text-align: center;
  font-size: 5.5rem;
  font-weight: 300;
  font-family: MOD20, serif;
  color: white;
  /* text-shadow: 5px 5px 0 black; */
}

.b-textWithHeader {
  width: 85%;
  position: relative;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: black;
  /* justify-content */
}
.b-textWithHeader--header {
  width: 30%;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 2.7rem;
  /* text-align: right; */
}
.b-textWithHeader--text {
  width: 60%;
  position: relative;
  top: 25px;
}

.b-contactForm {
  width: 60%;
  margin: auto;
}
.b-contactForm--textContainer {

}
.b-contactForm--header {
  font-size: 2.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 1rem;
}
.b-contactForm--text {
  width: 80%;
  margin: auto;
}
.b-contactForm--privacypolicy {
  padding-top: 25px;
  display: inline-block;
  text-align: justify;
  font-size: 14px;
  /* color: #525252; */
}
.b-contactForm--thankyou {
  margin: 0;
  text-align: center;
  height: 40.4667px;
  box-sizing: border-box;
  padding-top: 10px;
}

.b-dropmenu {
  position: relative;
  width: auto;
}
.b-dropmenu ul {
  list-style-type: none;
  position: absolute;
  z-index: 999;
  right: -15px;
  top: 30px;
  width: 150px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  display: none;
}
.b-dropmenu--item {
  line-height: 27px;
  font-size: 0.9rem;
  text-align: right;
}

.b-textfield {
  width: 100%;
  margin-bottom: 14px;
}
.b-calendarInput {
  position: relative;
}
.b-textfield--labelText, .b-textarea--labelText {
  font-size: 0.9rem;
  padding-bottom: 2px;
}
.b-textfield--input, .b-textarea--input {
  width: 100%;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgba(219, 219, 219, 0.7);
  color: black;
  text-overflow: ellipsis;
  border: solid 2px transparent;
  font-size: 14px;
  font-family: Freight Big Book;
  outline: 0;
}
.b-textfield--invalid input, .b-textarea--invalid textarea {
  background-color: rgba(255, 224, 227, 0.7);
}
.b-textarea--input.non-resizable {
  resize: none;
}

.b-threeImages {
  height: 90vh;
  width: 90%;
  margin: auto;
}
.b-threeImages--image {
  background-color: #d4d4d4;
  background-size: cover;
}
.b-threeImages--bigOne {
  width: 70%;
}
.b-threeImages--smallerOnes {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.b-threeImages--smallerOnes.left {
  padding-left: 2%;
}
.b-threeImages--smallerOnes.right {
  padding-right: 2%;
}
.b-threeImages--smallImage {
  width: 100%;
  height: 100%;
}
.b-threeImages--smallImage.up {
  margin-bottom: 2%;
}
.b-threeImages--smallImage.down {
  margin-top: 2%;
}

.b-galleryPage {

}
.b-galleryPage--top {
  text-align: center;
  margin: auto;
  margin-top: 5%;
}
.b-galleryPage--header {
  text-transform: uppercase;
  font-size: 3.2rem;
  letter-spacing: 0.2em;
}
.b-galleryPage--description p {
  margin: 0;
}
.b-galleryPage--contactButton {
  margin-top: 3rem;
}

.switch-wrapper {
  position: relative;
}

/* REACT CALENDAR */
.react-calendar__tile:disabled {
  background-color: inherit !important;
  color: #d4d4d4 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}
.react-calendar__month-view__weekdays__weekday {
  font-family: Freight Big Book !important;
}
.react-calendar__navigation__label {
  font-family: Freight Big Book !important;
  font-size: 1rem;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}